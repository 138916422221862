import {Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {FormUtilsService} from "../../shared/service/form-utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {debounceTime, filter} from "rxjs/operators";

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit {
    @Input() mainForm;
    @Input() smallInfo;

    // checkEmailLoading;
    // checkEmailText: any;

    form: FormGroup;

    constructor(public _payService: PayService,
                public changeDetector: ChangeDetectorRef,
                public _formBuilder: FormBuilder,
                public _formUtilsService: FormUtilsService) {
    }

    ngOnInit(): void {
        this.createUserForm();
    }

    createUserForm() {
        if (this.form && this.form.valid) {
            return;
        }

        this.form = this._formBuilder.group({
            email: [this._payService.config.email || '', Validators.compose([Validators.required, Validators.email])],
            confirm_email: [this._payService.config.email || '', Validators.compose([Validators.required, Validators.email])],
            first_name: [this._payService.config.first_name || '', [Validators.required, Validators.minLength(2)]],
            last_name: [this._payService.config.last_name || '', [Validators.required, Validators.minLength(2)]],
            id: [this._payService.config.id || ''],
        }, {
            validator: this._formUtilsService.mustMatch('email', 'confirm_email')
        });
            // isCheckEmail: ['', Validators.required],
        this.form.patchValue(this._payService.user);
        this.checkEmail('email');
        this.checkEmail('confirm_email');
        this.mainForm.addControl('user', this.form)
    }

    checkEmail(field): void {
        const wrongEmailEndings = ['con'];
        // this.form.get(field).valueChanges
        //     .pipe(
        //         debounceTime(100),
        //         filter(_ => this.form.get(field).valid))
        //     .subscribe(_ => {
        //         this.form.get('isCheckEmail').setValue(null);
        //     });

        this.form.get(field).valueChanges
            .pipe(
                debounceTime(1500),
                filter(_ => this.form.get(field).valid))
            .subscribe(res => {
                // this.checkEmailText = '';
                const emails = res.split('.');
                const emailEnd = res.split('.')[emails.length - 1];
                if (wrongEmailEndings.includes(emailEnd)) {
                    this.form.get(field).setErrors({wrongEnding: true});
                }
                // else {
                //     this.sendCheckEmailRequest(res);
                // }
                this.changeDetector.markForCheck();
            })
    }

    // sendCheckEmailRequest(res): void {
    //     if (this.form.get('email').valid && this.form.get('confirm_email').valid) {
    //         this.checkEmailLoading = true;
    //         this.changeDetector.markForCheck();
    //         this._payService.checkEmail({email: res})
    //             .subscribe(res => {
    //                 if (res && res.data && res.data.exists) {
    //                     this.checkEmailText = 'Welcome Back!<br/> Account access will be sent to your email.';
    //                 } else {
    //                     this.checkEmailText = 'Your account password will be sent to your email.';
    //                 }
    //                 this.form.get('isCheckEmail').setValue(true);
    //                 this.checkEmailLoading = false;
    //                 this.changeDetector.markForCheck();
    //             }, _ => {
    //                 this.form.get('isCheckEmail').setValue(false);
    //                 this.checkEmailLoading = false;
    //                 this.changeDetector.markForCheck();
    //         })
    //     }
    // }

}
