// Angular
import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpHeaders,
    HttpErrorResponse
} from '@angular/common/http';
// RxJS
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {LocalService} from './local.service';
import {LoggerService} from '../loggers/logger.service';
import moment from 'moment';

@Injectable()
export class InterceptService implements HttpInterceptor {

    constructor(private localService: LocalService,
                private _loggerService: LoggerService) {
    }

    // intercept request and add token
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headerConfig = {'accept': 'application/json', 'content-type': 'application/json'};

        if (this.localService.getItem(environment.clientId) && this.localService.getItem(environment.clientId).length) {
            headerConfig['x-api-key'] = this.localService.getItem(environment.clientId);
        }

        if (this.localService.getItem(environment.clientDomain) && this.localService.getItem(environment.clientDomain).length) {
            headerConfig['x-api-domain'] = this.localService.getItem(environment.clientDomain);
        }

        const userToken = this.localService.getItem(environment.authTokenKey + this.localService.getItem(environment.clientId));

        if (userToken) {
            headerConfig['Authorization'] = 'Bearer ' + userToken;
        }

        req = req.clone({
            setHeaders: headerConfig
        });

        const startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        return next.handle(req).pipe(
            tap(res => {
                if (res && res.type !== 0) {
                    const response = {
                        status: res['status'] ? res['status'] : null,
                        response: res['body'] ? res['body'] : null,
                    };
                    const data = { ...response, ...req, ...{start_time: startTime, end_time: moment().format('YYYY-MM-DD HH:mm:ss')}};
                    const message = res && res['message'];
                    this._loggerService.setTelemetry('network', 'info', message, data);
                }
            }),
            catchError((error) => {
                const response = {
                    status: error['status'] ? error['status'] : null,
                    response: error['error'] ? error['error'] : null,
                    headers: error['headers'] ? error['headers'] : null,
                    error: error['error'] ? error['error'] : null,
                    name: error['name'] ? error['name'] : null,
                    message: error['message'] ? error['message'] : null,
                    traceback: error['traceback'] ? error['traceback'] : null,
                };
                const data = { ...req, ...response, ...{start_time: startTime, end_time: moment().format('YYYY-MM-DD HH:mm:ss')}};
                return throwError(data);
            })
        );
    }
}
