import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";

@Component({
    selector: 'app-result-block',
    templateUrl: './result-block.component.html'
})
export class ResultBlockComponent {

    @Input() form;
    @Input() selectedMethod;
    @Input() card;

    @Output() callbackEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _payService: PayService) {
    }
}
