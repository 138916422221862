// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.payments.dev.zenzenzen.net/',
  threeDs: 'https://api-sandbox.3dsintegrator.com/v2/',
  authTokenKey: 'authce',
  clientDomain: 'clDmn',
  clientId: 'client9d77b308c149d5992a80073637e4d5',// От слиент ид
  // rollbarId: '0cda04362fc24f0cbc3fdb28e54d5ef7', // От слиент ид,
  loggerApiUrl: 'https://logger.payments.dev.zenzenzen.net',
  loggerToken: '9448eba6-ec20-4c23-b567-29d354d81d62',
  closeHtmlPatch: 'https://e.payprocessing.dev.zenzenzen.net/close-iframe.html'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
