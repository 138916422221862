import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import SignaturePad from "signature_pad";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html'
})
export class SignatureComponent implements AfterViewInit {

    // signature
    @ViewChild('signature', {static: false}) signatureCanvas: HTMLCanvasElement;
    @ViewChild('signatureBlockForm', {static: false}) signatureBlock: HTMLCanvasElement;
    signaturePad: any;

    @Output() callbackEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _payService: PayService,
                public dialogRef: MatDialogRef<SignatureComponent>,) {
    }

    ngAfterViewInit() {
        this._payService.matomoTracker.trackEvent('Form', 'Form action', 'Signature part');
        this._payService.loading = false;
        this.signatureCanvas['nativeElement'].width = this.signatureBlock['nativeElement'].clientWidth;
        this.signatureCanvas['nativeElement'].height = 140;
        this.signaturePad = new SignaturePad(this.signatureCanvas['nativeElement'], {
            backgroundColor: '#fafafa',
            penColor: 'rgb(0, 0, 0)'
        });
    }

    clearSignatureCanvas() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    saveSignature() {
        if (!this.isSignaturePadEmpty) {
            this._payService.loading = true;
            const data = {signature: this.signaturePad.toDataURL()};
            if (this.dialogRef.close) {
                return this.dialogRef.close(data);
            }
            this.callbackEmitter.emit({signature: this.signaturePad.toDataURL()});
        }
    }

    get isSignaturePadEmpty(): boolean {
        return this.signaturePad && this.signaturePad.isEmpty();
    }
}
