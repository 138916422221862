import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserFormComponent} from "./user-form/user-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CardFormComponent} from "./card-form/card-form.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CreditCardDirectivesModule} from "angular-cc-library";
import {BillingComponent} from "./billing/billing.component";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {PaidStatusBlockComponent} from "./paid-status-block/paid-status-block.component";
import {DiscountComponent} from "./discount/discount.component";
import {ResultBlockComponent} from "./result-block/result-block.component";
import {MatListModule} from "@angular/material/list";
import {SignatureComponent} from "./signature/signature.component";
import {TermsComponent} from "./terms/terms.component";
import {SelectCardComponent} from "./select-card/select-card.component";
import {MatRadioModule} from "@angular/material/radio";
import {BlockCopyPasteDirective} from "../shared/directive/block-copy-paste.directive";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FrameModalComponent} from "./frame-modal/frame-modal.component";
import {SafeUrlPipe} from "../shared/directive/safe-url.pipe";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        CreditCardDirectivesModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatRadioModule,
    ],
    declarations: [
        UserFormComponent,
        CardFormComponent,
        BillingComponent,
        PaidStatusBlockComponent,
        DiscountComponent,
        ResultBlockComponent,
        SignatureComponent,
        TermsComponent,
        SelectCardComponent,
        FrameModalComponent,
        BlockCopyPasteDirective,
        SafeUrlPipe
    ],
    entryComponents: [
        PaidStatusBlockComponent,
        TermsComponent,
        SelectCardComponent,
        SignatureComponent,
        DiscountComponent,
        CardFormComponent,
        FrameModalComponent,
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ],
    exports: [
        UserFormComponent,
        CardFormComponent,
        BillingComponent,
        PaidStatusBlockComponent,
        DiscountComponent,
        ResultBlockComponent,
        SignatureComponent,
        TermsComponent,
        SelectCardComponent,
        FrameModalComponent,
        SafeUrlPipe
    ]
})
export class ComponentsModule {
}
