import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {catchError, debounceTime} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../environments/environment';
import {PayService} from '../shared/service/pay.service';
import {LocalService} from "../shared/service/local.service";

import {LoggerService} from '../shared/loggers/logger.service';
import {FormUtilsService} from "../shared/service/form-utils.service";
import _ from 'lodash';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    paidStatusBlock = {
        loading: false,
        payRequestReturnUrl: '',
        paidStatusText: '',
        paidSuccess: null,
        showTryAgainButton: false,
        showRedirectToMain: false,
        callback: this.tryAgain.bind(this)
    }
    isLoadingAllForm = false;

    configState = {
        isSelectMethodPay: false,
        isResult: false,
        isPaidStatus: false,
        isPaySignature: false,
        isAcceptTerms: false,
        isTip: false,
        isInsurance: false,
        isIframe: false,
        isDiscount: false
    };

    lastOpenState = {
        title: '',
        path: '',
        state: ''
    };

    sendPayData: any = {};

    currentState = {
        title: '',
        path: '',
        state: ''
    };

    history = [];

    form: any = {};
    card: any = {};
    selectedMethod: any = {
        type: ''
    };

    iframeData = {
        loading: false,
        url: '',
        subscribeMessage: false
    }

    donation = {
        active: false,
        options: [],
        title: 'Amount'
    }

    mainForm: FormGroup;
    balanceForm: FormGroup;
    discountCoupon: FormControl = new FormControl(null, Validators.required);

    isStageTipHasPassed = false;
    isStageInsuranceHasPassed = false;
    tips = [
        {title: 'No Tip', sum: null, id: '0'}
    ];
    tipSum = 0;
    selectedTip = this.tips[0];

    insurance = new FormControl();


    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                public _payService: PayService,
                private localService: LocalService,
                private _loggerService: LoggerService,
                public _formUtilsService: FormUtilsService,
                private changeDetector: ChangeDetectorRef) {
        this.localService.checkLocalStorage();
    }

    ngOnInit() {
        this.mainForm = this.fb.group({})
        this.isLoadingAllForm = true;
        // необходи для проверки на место открытия формы (на сайте или отдельно в новой вкладке)
        if (environment.production) {
            if (window.self === window.top) {
                return;
            }
        }
        this.getRouteParams();
        this._payService.goStateEmitter.subscribe(res => {
            if (res && res.patch) {
                this.go(res.title, res.patch);
            }
        })
    }

    getRouteParams(): void {
        this.route.queryParams.subscribe(res => {
            if (res && res.form) {
                this._payService.setConfig(res);

                this._payService.setDonationParams(res, this.donation)
                this._payService.checkUrlConfig(this.checkIsComplete.bind(this));
                this._payService.subscribePusher(this.paidStatusBlock);
            }
        });
    }

    checkIsComplete() {

        if (this._payService.config.isComplete) {
            this.isLoadingAllForm = false;
            if (this._payService.config.PayerID && this._payService.config.paymentId) {
                this._payService.sendComplete(null, this.paidStatusBlock, this.changeDetector);
            } else {
                setTimeout(() => {
                    this._payService.showPaidStatus(this.paidStatusBlock, false, 'Payment was canceled');
                    this._payService.sendPostMessage({
                        type: 'initForm',
                        data: {id: null, name: 'Paypal Canceled', total: null}
                    });
                }, 2000)
            }
            return this.changeState('isPaidStatus');
        }
        if (this._payService.config.form) {
            this.getForm(this._payService.config.form);
        }
        this._payService.loadPaymentSettings();
        if (this._payService.config.hasAuthTokenParams) {
            this._payService.getUserByToken().subscribe();
        }
        // return this.initTip();
        // return this.go('Insurance', 'isInsurance');
        this.changeState('isSelectMethodPay');
    }

    getUser(): void {
        this._payService.getUser(this.paidStatusBlock, this.dynamicFunction.bind(this));
    }

    checkUser(): void {
        if (this._payService.config.hasAuthTokenParams && this._payService.config.auth != 'guest') {
            this.getUser();
        } else {
            this.checkAuthType(this._payService.config.auth)
            this._payService.removeToken();
        }
    }

    dynamicFunction(data: any): void {
        if (!data || !data.functionName) {
            return;
        }
        if (data.key) {
            const val = _.get(this, data.key, '')
            return this[data.functionName](val);
        }
        if (data.data && data.data.key) {
            return this[data.functionName](data.data.title, data.data.key);
        }
        this[data.functionName](data.data);
    }

    checkAuthType(type: string = 'all') {
        this._payService.userCards = [];
        if (type === 'guest' || type === 'all') {
            this.go('Information', 'isUser');
        }
    }

    //Discount

    goDiscount(): void {
        this.lastOpenState = {...this.currentState};
        this.go('Discount Coupon', 'isDiscount');
    }

    saveDiscount(res): void {
        this.sendPayData = {...this.sendPayData, ...{discount: this.discountCoupon.value}};
        this.form.total = res.discount.total;
        this.form.oldTotal = res.total;
        this.form.oldMethods = [...this.form.methods];
        this.form.methods = res.methods;
        this.back(null);
    }

    deleteDiscountCoupon(): void {
        this.discountCoupon.setErrors(null);
        this.discountCoupon.setValue(null);
        this.discountCoupon.markAsUntouched();
        if (this.sendPayData.discount) {
            delete this.sendPayData.discount;
        }
        if (this.form.oldTotal || this.form.oldTotal === 0) {
            this.form.total = this.form.oldTotal;
            delete this.form.oldTotal;
        }
        if (this.form.oldMethods) {
            this.form.methods = this.form.oldMethods;
            delete this.form.oldMethods
        }
    }

    // Form

    getForm(formId: string) {
        this._payService.getForm(formId).subscribe(res => {
            if (res) {
                this.form = res.data;

                if (this._payService.config.addAddonTotal) {
                    this.form.total = Number(this._payService.config.addAddonTotal);
                }
                this._payService.form = this.form;
                if (this.form && this.form.methods && this.form.methods.length) {
                    const indexMethodCard = this.form.methods.findIndex(m => m.type === 'creditcard');
                    if (indexMethodCard != -1) {
                        this.form.methods.splice(0, 0, this.form.methods.splice(indexMethodCard, 1)[0]);
                        this.selectMethod(this.form.methods[0]);
                    }
                    this.form.methods.map(m => {
                        if (m.type === 'digitalwallet') {
                            this.selectedMethod = m;
                            this.connectStripe(m);
                        }
                    })
                }
                this.checkBalanceForm();
                this._payService.sendPostMessage({
                    type: 'initForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this._payService.matomoTracker.setEcommerceView(this.form.id, this.form.name, '', this.form.total);
                this._payService.matomoTracker.trackPageView();
                this.isLoadingAllForm = false;
            }

            // this.paidStatusBlock.loading = true;
            // this.go('', 'isPaidStatus');
            // this.checkPayRequestError({});
            // return;

        });
    }

    connectStripe(m): void {
        if (m.public_data && m.public_data.public_key) {
            const script = document.createElement('script');
            script.id = 'stripe-script';
            script.src = 'https://js.stripe.com/v3/';
            document.head.append(script);
            script.onload = (event) => {
                const fn = window['Stripe'];
                const stripe = fn(m.public_data.public_key, {
                    apiVersion: '2020-08-27',
                });
                let paymentRequest = stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: this.form.name || '',
                        amount: this.form.total * 100 || null,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });
                const elements = stripe.elements();
                const prButton = elements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,
                });
                console.log('------ canMakePayment');
                paymentRequest.canMakePayment().then(function (result) {
                    console.log('------ result');
                    console.log(result);
                    if (result) {
                        prButton.mount('#payment-request-button');
                    } else {
                        document.getElementById('payment-request-button').style.display = 'none';
                    }
                });
                paymentRequest.on('paymentmethod', async (e) => {
                    if (e && e.paymentMethod) {
                        const p = e.paymentMethod;
                        let names = [];
                        if (e.payerName && e.payerName.length) {
                            names = e.payerName.split(' ');
                        }
                        const data = {
                            form_id: this._payService.config.form,
                            method_id: this.selectedMethod.id,
                            push_id: this._payService.pushInfo.push_id,
                            user: {
                                email: e.payerEmail,
                                confirm_email: e.payerEmail,
                                first_name: names.length > 0 ? names[0] : 'unknown',
                                last_name: names.length > 0 && names[1] && names[1].length ? names[1] : 'unknown',
                            },
                            returnUrl: `${environment.closeHtmlPatch}?parentHost=${location.hostname}`,
                            cancelUrl: this._payService.config.cancelUrl,
                            source: p.id,
                            creditcard: {
                                month: p.card.exp_month,
                                year: p.card.exp_year,
                                number: p.card.last4,
                                cvv: 999,
                                billing_country: p.billing_details.address.country || 'US',
                                billing_zipcode: p.billing_details.address.postal_code || '90028',
                                billing_address: p.billing_details.address.line1 || '7001 Hollywood Blvd.',
                                billing_city: p.billing_details.address.city || 'Los Angeles',
                                billing_state: p.billing_details.address.state || 'CA',
                                first_name: names.length > 0 ? names[0] : 'unknown',
                                last_name: names.length > 0 && names[1] && names[1].length ? names[1] : 'unknown',
                            }
                        }
                        this._payService.checkMetadata(data);

                        if (this.sendPayData.discount) {
                            data['discount'] = this.sendPayData.discount;
                        }
                        this.sendPayData = data;
                        this.payRequest(data, m.type);
                        console.log(e);
                        e.complete('success');
                    }
                });
            }
        }
    }

    checkBalanceForm(): void {
        if (this.form.data && this.form.data.balance) {
            this._payService.config.showTotalBalanceForm = !this._payService.config.total || this._payService.config.total <= 0;
            this.balanceForm = this.fb.group({
                total: [this._payService.config.total, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
                    Validators.min(1)]]
            });
        }
    }

    setDonationOptions(value) {
        if (this.balanceForm && this.balanceForm.get('total')) {
            this.balanceForm.get('total').setValue(value)
        }
    }

    selectMethod(method: any) {
        if (this.selectedMethod && method && method.id === this.selectedMethod.id) {
            return;
        }
        if (this.form.data && this.form.data.balance && this.balanceForm) {
            if (this.balanceForm.invalid) {
                this._formUtilsService.validation(this.balanceForm);
                return;
            }
        }
        this.history = [{
            "title": "",
            "path": "isSelectMethodPay",
            "state": "isSelectMethodPay"
        }];
        this.selectedMethod = method;
        this._payService.sendPostMessage({
            type: 'selectedMethod',
            data: {type: method.type}
        });
        if (method.type === 'creditcard') {
            this._payService.getUserCards();
            return this.go('Card Information', 'isCard')
        } else if (method.type === 'balance') {
            if (this._payService.user.balance < this.form.total) {
                return this._payService.openSnackBar('The amount of payment is more than you have on the balance', true);
            }
        }
        this.pay(this.selectedMethod.type);
    }

    openCardsList(): void {
        if (this.form.data && this.form.data.balance && this.balanceForm) {
            if (this.balanceForm.invalid) {
                return this._formUtilsService.validation(this.balanceForm);
            }
        }
        this.go('Select Card', 'isSelectCard');
    }

    selectCard(card) {
        this.card = card;
        this.changeDetector.markForCheck();
        this.go('Cvv', 'isCard')
    }

    pay(methodType: string) {
        // if (this.mainForm.invalid) {
        //     return this._formUtilsService.validation(this.mainForm)
        // }
        if (this.form.data && this.form.data.balance && this.balanceForm) {
            if (this.balanceForm.invalid) {
                return this._formUtilsService.validation(this.balanceForm);
            }
        }
        if (this.currentState.path === 'isCard' && methodType === 'creditcard') {
            if (this.mainForm && this.mainForm.get('card')) {
                if (this.mainForm.get('card').invalid) {
                    return this._formUtilsService.validation(this.mainForm.get('card'));
                }
                if (!this.card || !this.card.id) {
                    const cardValue = this.mainForm.get('card').value;
                    cardValue.save = cardValue.save ? 1 : 0;
                    this.card = cardValue;
                    return this.go('Billing Information', 'isBilling');
                }
            }
        }
        if (this.currentState.path === 'isBilling') {
            if (this.mainForm && this.mainForm.get('billing')) {
                if (this.mainForm.get('billing').invalid) {
                    return this._formUtilsService.validation(this.mainForm.get('billing'));
                }
                this.card = {...this.card, ...this.mainForm.get('billing').value};
            }
            if (!this._payService.user.id) {
                return this.go('Information', 'isUser');
            }
        }

        if (!this.mainForm.get('user') && !this._payService.user.id) {
            return this.checkUser();
        } else {
            if (this.mainForm && this.mainForm.get('user')) {
                if (this.mainForm.get('user').invalid) {
                    if (this.currentState.path !== 'isUser') {
                        this.go('Information', 'isUser');
                    }
                    return this._formUtilsService.validation(this.mainForm.get('user'));
                } else {
                    this._payService.user = this.mainForm.get('user').value;
                }
            }
            if ((this.currentState.path === 'isSelectMethodPay' || this.currentState.path === 'isCard')
                && !this._payService.user.id && methodType != 'creditcard') {
                return this.go('Information', 'isUser');
            }
        }

        this._payService.sendPostMessage({
            type: 'paymentMethod',
            data: {method: methodType, id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this._payService.matomoTracker.trackEvent('Form', 'Form payment method', methodType);
        this._payService.matomoTracker.addEcommerceItem(this.form.id, this.form.name, '', parseFloat(this.form.total), 1);
        this._payService.matomoTracker.trackPageView();
        this._payService.loading = true;
        let returnUrl = methodType === 'creditcard' || methodType === 'digitalwallet' ?
            `${environment.closeHtmlPatch}?parentHost=${location.hostname}` :
            this._payService.config.returnUrl
        let data = {
            form_id: this._payService.config.form,
            method_id: this.selectedMethod.id,
            push_id: this._payService.pushInfo.push_id,
            user: this._payService.user.id ? this._payService.user : this._formUtilsService.checkEmptyField(this.mainForm, 'user'),
            returnUrl: returnUrl,
            cancelUrl: this._payService.config.cancelUrl,
        };
        if (this._payService.config.parent_id) {
            data['parent_id'] = this._payService.config.parent_id;
        }
        if (this.form.data.balance) {
            data = {...data, ...this.balanceForm.value};
        }

        if (methodType === 'creditcard') {
            if (this.card && this.card.id) {
                data['creditcard'] = {
                    id: this.card.id,
                    cvv: this.mainForm.get('card').get('cvv').value
                };
                this._payService.sendPostMessage({
                    type: 'withSavedVard',
                    data: {card: true}
                });
            } else {
                this.prepareCreditcardDate();
                data['creditcard'] = this.card;
            }
        }

        this._payService.checkMetadata(data);

        if (this.sendPayData.discount) {
            data['discount'] = this.sendPayData.discount;
        }
        if (methodType === 'cashapp') {
            data.returnUrl = this._payService.config.success_url;
        }

        this.sendPayData = data;
        this.payRequest(data, methodType);
    }

    prepareCreditcardDate() {
        if (this.mainForm.get('card')) {
            const date: string = this.mainForm.get('card').get('expirationDate').value;
            const split = date.split(' / ');
            this.card.month = split[0];
            this.card.year = split[1];
        }

        this.card.first_name = this._payService.user.first_name;
        this.card.last_name = this._payService.user.last_name;

        if (this.card.expirationDate) {
            delete this.card.expirationDate;
        }
    }

    payRequest(data: any, methodType: string) {
        if (this._payService.config.insuranceTotal && !this.isStageInsuranceHasPassed) {
            this.paidStatusBlock.showTryAgainButton = false;
            this.paidStatusBlock.loading = false;
            this._payService.loading = false;
            return this.go('Insurance', 'isInsurance');
        }
        if (!this.isStageTipHasPassed) {
            return this.initTip();
        }
        if (this._payService.paymentSettings.terms_active && (!data.accept_terms)) {
            return this.initAcceptTerms();
        }
        if (this._payService.paymentSettings.signature_active && (!data.signature || !data.signature.length)) {
            return this.initSignature();
        }
        if (this._payService.config.addons && this._payService.config.addons.length) {
            if (this._payService.config.onlyAddon) {
                const ids = this._payService.config.addons.map(addon => addon.formId);
                data = {...data, ...{form_ids: ids}};
                if (this._payService.config.insurances && this._payService.config.insurances.length) {
                    data = this.addInsurances(ids, this._payService.config.insurances, data)
                }
                delete data.form_id;
            } else {
                data.addons = this._payService.config.addons;
                if (this._payService.config.insurances && this._payService.config.insurances.length) {
                    data = this.addInsurances(data.addons, this._payService.config.insurances, data)
                }
            }
        } else {
            if (data.addons) {
                delete data.addons;
            }
        }
        this.paidStatusBlock.loading = true;
        this.go('', 'isPaidStatus');
        var request = this._payService.pay(data)
        if (this.form && this.form.data.balance) {
            request = this._payService.payBalance(data)
        }
        request.pipe(
            catchError(res => {
                this._payService.sendToken(res);
                if (res && res.error && res.error.error && (res.error.error.accept_terms || res.error.error.signature)) {
                    this.checkPayRequestError(res.error);
                    return of(undefined);
                }
                this._loggerService.error('network', {...res});
                this.checkPayRequestError(res.error);
                return of(undefined);
            }),
        ).subscribe(res => {
            if (res) {
                const data = res.body.data;
                this._payService.sendPostMessage({
                    type: 'submitForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this._payService.sendToken(res);
                this._payService.addSuccessText(data);
                this._payService.matomoTracker.trackEvent('Form', 'Submit form', this.form.name);
                this._payService.matomoTracker.trackEcommerceCartUpdate(parseFloat(this.form.total));
                this._payService.matomoTracker.trackPageView();
                if (data && data.return_url && data.return_url.length && (methodType === 'creditcard' || methodType === 'digitalwallet')) {
                    this.subscribeIframe3DsMessage();
                    this.iframeData.url = data.return_url;
                    this.go('Iframe', 'isIframe');
                    return;
                }
                if (data && data.success && (methodType === 'cryptocurrency' || methodType === 'paypal' || methodType === 'redirect' || methodType === 'cashapp')) {
                    if (this._payService.config.sendPostMessageInsteadRedirect) {
                        this._payService.sendPostMessage({
                            type: 'hideTelegramBackButton',
                            hide: true
                        })
                    }
                    this.paidStatusBlock.loading = false;
                    this.paidStatusBlock.paidSuccess = 'redirect';
                    this.paidStatusBlock.payRequestReturnUrl = data.return_url;
                    this.paidStatusBlock.paidStatusText = 'Redirecting to the site...';
                    window.open(data.return_url, '_parent');
                    this._payService.loading = false;
                } else {
                    this._payService.checkPaidStatus(data, this.paidStatusBlock);
                }
            }
        });
    }

    addInsurances(addons, insureIds, data) {
        const insurances = [];
        addons.map(a => {
             if (insureIds.find(i => i === a)) {
                insurances.push(1);
            } else {
                insurances.push(0);
            }
        })
        return {...data, ...{addons_insurances: insurances}}
    }

    subscribeIframe3DsMessage() {
        if (this.iframeData.subscribeMessage) {
            return;
        }
        window.addEventListener('message', (ev) => {
            if (ev && ev.data && typeof ev.data === 'string') {
                const data = JSON.parse(ev.data)
                if (data && data.close_confirm_iframe) {
                    this.go('', 'isPaidStatus');
                    this._payService.sendComplete(data, this.paidStatusBlock, this.changeDetector);
                }
            }
        }, false);
        this.iframeData.subscribeMessage = true;
    }

    checkPayRequestError(res: any) {
        // res = {
        //     "message": "You cannot purchase a package if you have the same Active and Valid (and 2 more errors)",
        //     "error": {
        //         "form_ids.0": [
        //             "You cannot purchase a package if you have the same Active and Valid"
        //         ],
        //         "form_ids.1": [
        //             "You cannot purchase a package if you have the same Active and Valid"
        //         ],
        //     },
        //     "code": 422
        // };
        if (res && res.error && res.error.accept_terms) {
            this.initAcceptTerms(res.error.accept_terms[0]);
        } else if (res && res.error && res.error.signature) {
            this.initSignature(res.error.signature[0]);
        } else if (res && res.error && res.error.method_id) {
            this.showDefaultError(res.error.method_id[0]);
        } else if (res && res.error && res.error.form_id) {
            this.showDefaultError(res.error.form_id[0]);
        } else if (res && res.error && this.hasAddonError(res.error)) {
            const mess = this.hasAddonError(res.error, true) || '';
            const textAddonAlreadyPay = 'You cannot purchase a package if you have the same Active and Valid';
            if (mess.includes('Cannot purchase addon')) {
                this.paidStatusBlock.showRedirectToMain = true;
                this.showDefaultError(mess, false, true);
            } else if (mess.includes(textAddonAlreadyPay)) {
                Object.keys(res.error).map(key => {
                    if (res.error[key].includes(textAddonAlreadyPay)) {
                        const index = key.includes('.') ? key.split('.')[1] : -1;
                        let addon = this._payService.config.addons[index];
                        if (addon) {
                            this._payService.config.addons[index] = {...addon, ...{isAlreadyPay: true}};
                            this.form.total = Number(this.form.total) - Number(addon.total);
                            if (this.selectedTip) {
                                const tipId = this.selectedTip.id
                                if (tipId && (tipId === '2' || tipId === '3' || tipId === '4')) {
                                    this.initTip(true);
                                    this.selectedTip = this.tips.find(t => t.id === tipId);
                                    this.sendPayData.tip = this.selectedTip.sum;
                                }
                            }

                        }
                    }
                })
                this._payService.loading = false;
                this.paidStatusBlock.loading = false;
                this.paidStatusBlock.callback = this.withoutAlreadyPayAddon.bind(this);
                this.setHistory('isPaidStatus')
                this._payService.showPaidStatus(this.paidStatusBlock, false, 'showAddons')
            }
        } else {
            if (res && res.error && res.error.message) {
                this.showDefaultError(res.error.message);
            } else {
                this.showDefaultError('Your transaction is in pending status. You will receive complete details of your Transaction by email. There is no need to make the payment again at this time.');
            }
        }
    }

    hasAddonError(error, getMess = false): any {
        let mess = '';
        const has = Object.keys(error).map(k => {
            if (!mess.length) {
                mess = error[k] && error[k][0];
            }
            return k.includes('form_ids')
        })
        return getMess ? mess : !!has && !!has.length
    }

    withoutAlreadyPayAddon() {
        this._payService.config.addons = this._payService.config.addons.filter(a => !a.isAlreadyPay);
        this._payService.loading = true;
        this._payService.showPaidStatus(this.paidStatusBlock, false, '');
        this.payRequest(this.sendPayData, this.selectedMethod.type);
    }

    showDefaultError(message, showTryAgain = true, showRedirectToMain = false): void {
        this._payService.loading = false;
        this.paidStatusBlock.callback = this.tryAgain.bind(this);
        this.paidStatusBlock.showTryAgainButton = showTryAgain;
        this.paidStatusBlock.showRedirectToMain = showRedirectToMain;
        this.paidStatusBlock.loading = false;
        this.setHistory('isPaidStatus')
        this._payService.showPaidStatus(this.paidStatusBlock, false, message)
        this._payService.openSnackBar(message, true);
    }

    tryAgain() {
        if (this._payService.config.isComplete) {
            this._payService.config.isComplete = false;
            this.checkIsComplete();
        } else {
            this.history = [];
            if (this.card && this.card.id) {
                this.card = {};
                if (this.mainForm.get('card')) {
                    if (this.mainForm.get('card').get('cvv')) {
                        this.mainForm.get('card').get('cvv').setValue('')
                    }
                }
            }
            if (this.selectedMethod && this.selectedMethod.type === 'digitalwallet') {
                this.connectStripe(this.selectedMethod);
            }
            this.selectedMethod = null;
            this.isStageTipHasPassed = false;
            this.history = [];
            this.go('', 'isSelectMethodPay');
        }
    }


    // financialSupport

    initTip(onlyCount = false) {
        // this.form.total = typeof this.form.withoutTipTotal === 'number' ? this.form.withoutTipTotal : this.form.total;
        const total = this.form.oldTotal || this.form.total;
        this.tips = [
            {title: 'No Tip', sum: null, id: '0'}
        ];
        this.tips.push({
            title: '10%',
            sum: Math.floor(total * 0.10),
            id: '2'
        })
        this.tips.push({
            title: '15%',
            sum: Math.floor(total * 0.15),
            id: '3'
        })
        this.tips.push({
            title: '20%',
            sum: Math.floor(total * 0.20),
            id: '4'
        })
        if (onlyCount) {
            return;
        }
        const customValue = this.selectedTip.sum && this.selectedTip.sum.value || ''
        this.tips.push({
            title: 'Custom Tip',
            sum: new FormControl(customValue, [Validators.required, Validators.min(1)]),
            id: '5'
        })
        this.paidStatusBlock.showTryAgainButton = false;
        this.paidStatusBlock.loading = false;
        this._payService.loading = false;
        this.go('Tip', 'isTip');

        this.tips[4].sum.valueChanges.pipe(debounceTime(500)).subscribe(v => {
            if (v) {
                this.tipSum = v;
            }
        })
    }

    selectTip(tip) {
        if (this.selectedTip && this.selectedTip.id === tip.id) {
            return;
        }
        this.selectedTip = tip;
        if (typeof this.selectedTip.sum === 'number') {
            this.tipSum = this.selectedTip.sum;
        } else {
            this.tipSum = 0;
        }
        this.tips[4].sum.setValue('');
    }

    saveInsurance() {
        this.isStageInsuranceHasPassed = true;
        this.sendPayData = {...this.sendPayData, ...{insurance: this.insurance.value ? 1 : 0}};
        this.payRequest(this.sendPayData, this.selectedMethod.type);
    }

    saveTip() {
        if (!this.selectedTip.sum) {
            this.isStageTipHasPassed = true;
            if (this.sendPayData.tip >= 0) {
                delete this.sendPayData.tip;
            }
            return this.payRequest(this.sendPayData, this.selectedMethod.type);
        }
        if (this.selectedTip.sum && this.selectedTip.sum.invalid) {
            return this.tips[4].sum.markAsTouched();
        }
        this.isStageTipHasPassed = true;
        const sum = this.selectedTip.sum && this.selectedTip.sum.value ? this.selectedTip.sum.value : this.selectedTip.sum;
        this._payService.sendPostMessage({
            type: 'tips',
            data: {sum: sum}
        });
        this.sendPayData = {...this.sendPayData, ...{tip: sum}};
        this.payRequest(this.sendPayData, this.selectedMethod.type);
    }


    // Signature

    // set default value in signature pad
    initSignature(error = '') {
        const message = error && error.length ? error : 'The signature is required.';
        this.paidStatusBlock.showTryAgainButton = false;
        this.paidStatusBlock.loading = false;
        this.go('Signature', 'isPaySignature');
        this._payService.openSnackBar(message, true);
        this._payService.sendPostMessage({
            type: 'FormError',
            data: {error: 'signature', id: this.form.id, name: this.form.name, total: this.form.total}
        });
    }

    saveSignature(data) {
        this.sendPayData = {...this.sendPayData, ...data};
        this._payService.sendPostMessage({
            type: 'signature',
            data: {signature: true}
        });
        this.payRequest(this.sendPayData, this.selectedMethod.type);
    }

    // accept payment terms

    initAcceptTerms(error = '') {
        this._payService.loading = false;
        const message = error && error.length ? error : 'The accept terms field is required.';
        this._payService.openSnackBar(message, true);
        this._payService.matomoTracker.trackEvent('Form', 'Form action', 'Terms part');
        this._payService.sendPostMessage({
            type: 'FormError',
            data: {error: 'terms', id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this.paidStatusBlock.showTryAgainButton = false;
        this.paidStatusBlock.loading = false;
        return this.go('Accept Terms', 'isAcceptTerms');
    }

    saveTerms(data) {
        this._payService.loading = true;
        this.sendPayData = {...this.sendPayData, ...data};
        this._payService.sendPostMessage({
            type: 'terms',
            data: {terms: true}
        });
        this.payRequest(this.sendPayData, this.selectedMethod.type);
    }

    // step by state

    changeState(selectField: string, isSetHistory = true) {
        if (Object(this.configState).hasOwnProperty(selectField)) {
            Object.keys(this.configState).map(field => {
                this.configState[field] = false;
            });

            if (selectField && selectField.length) {
                this.configState[selectField] = true;
            }
            this.currentState.state = selectField;
        }
        this.currentState.path = selectField;
        this._loggerService.setTelemetry('routing', 'info', 'changed routing', {urlKey: selectField});
        if (isSetHistory) {
            this.setHistory(selectField);
        }
        this.changeDetector.detectChanges();
        this.changeDetector.markForCheck();
    }

    setHistory(selectField): void {
        if (selectField !== 'isDiscount' && selectField !== 'isIframe' &&
            ((selectField === 'isPaidStatus' && !this.paidStatusBlock.loading) || selectField !== 'isPaidStatus')) {
            this.history.push({...this.currentState});
        }
    }

    back(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (this.configState.isDiscount && this.lastOpenState) {
            if (this.history && this.history.length) {
                this.history.splice(this.history.length - 1);
            }
            if (this.lastOpenState.state && this.lastOpenState.state.length) {
                if (this.lastOpenState.state != this.lastOpenState.path) {
                    this.changeState(this.lastOpenState.state, false)
                }
            }
            return this.go(this.lastOpenState.title, this.lastOpenState.path)
        }
        return this.backHistory();
    }

    backHistory(): void {
        const hasFirst = this.history.findIndex(i => i.path === 'isSelectMethodPay');
        if (this.isHistory && (this.history.length !== 2 || hasFirst == -1)) {
            this.history.splice(this.history.length - 1, 1);
            const item = {...this.history[this.history.length - 1]};
            this.history.splice(this.history.length - 1, 1);
            if (item && item.path) {
                switch (item.path) {
                    case 'isCard': {
                        if (this.card && this.card.id && this.mainForm.get('card')) {
                            this.mainForm.get('card').get('cvv').setValue('');
                            this.mainForm.get('card').get('cvv').markAsUntouched();
                        }
                        break;
                    }
                    case 'isSelectCard': {
                        if (this.card && this.card.id) {
                            this.card = {};
                        }
                        break;
                    }
                    case 'isInsurance': {
                        this.isStageInsuranceHasPassed = false;
                        break;
                    }
                    case 'isTip': {
                        this.isStageTipHasPassed = false;
                        break;
                    }
                }
                if (item.state && item.state.length) {
                    if (item.state != item.path) {
                        this.changeState(item.state, false)
                    }
                }
                this.go(item.title, item.path);
            }
        } else {
            if (this.history && this.history.length) {
                this.history = [];
                this.selectedMethod = null;
                this.go('', 'isSelectMethodPay');
            }
        }
    }

    get isHistory(): boolean {
        return this.history && this.history.length && this.history.length > 1;
    }

    go(title: string = '', patch: string) {
        this.currentState.title = title;
        this.changeState(patch);
    }

    // show function
    showTopBar() {
        if (this.currentState.path === 'isIframe') {
            return false;
        }
        if (this.currentState && this.currentState.path === 'isPaidStatus' && this.paidStatusBlock.loading) {
            return false;
        }
        if (this.currentState.path === 'isDiscount') {
            return true;
        }
        const stateItems = ['isResult', 'isPaidStatus', 'isPaySignature', 'isAcceptTerms', 'isTip', 'isInsurance', 'isDiscount']
        const hasConfigStateInHistory = stateItems.includes(this.currentState.path);
        return this.isHistory && hasConfigStateInHistory;
    }

    get showMethodBar(): boolean {
        if ((!this.card || !this.card.id) && this.currentState.path === 'isCard') {
            return false;
        }
        const stateItems = ['isCard', 'isBilling', 'isSelectCard', 'isUser']
        return stateItems.includes(this.currentState.path);
    }

}


