export const COMMON: any = {
    CARD_ICONS: [
        {
            name: 'visa',
            path: 'assets/icons/visa.svg'
        },
        {
            name: 'mastercard',
            path: 'assets/icons/mastercard.svg'
        },
        {
            name: 'amex',
            path: 'assets/icons/amex.svg'
        },
        {
            name: 'unionpay',
            path: 'assets/icons/unionpay.svg'
        },
    ]
};