import {Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, Inject} from '@angular/core';
import {FormUtilsService} from "../../shared/service/form-utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CreditCardValidators} from "angular-cc-library";
import {CARDS} from "../../shared/consts/cards.const";
import {COMMON} from "../../shared/consts/common.const";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-card-form',
    templateUrl: './card-form.component.html'
})
export class CardFormComponent implements OnInit {
    @Input() mainForm;
    @Input() card;
    @Input() smallInfo;

    form: FormGroup;
    cards: any = CARDS;

    cardIcons: any = COMMON.CARD_ICONS;

    constructor(public changeDetector: ChangeDetectorRef,
                public _formBuilder: FormBuilder,
                public _formUtilsService: FormUtilsService,
                public dialogRef: MatDialogRef<CardFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if(!this.mainForm){
            this.mainForm = this.data.mainForm;
            this.card = this.data.card;
            this.smallInfo = this.data.smallInfo;
        }
        this.createForm();
    }

    createForm() {
        if (this.form && this.form.valid) {
            return;
        }
        this.form = this._formBuilder.group({
            number: ['', [CreditCardValidators.validateCCNumber]],
            save: [''],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
            month: [''],
            year: [''],
            first_name: [''],
            last_name: [''],
        });
        if (this.mainForm.get('card')) {
            this.mainForm.removeControl('card');
        }
        if (this.card && !this.card.id) {
            this.form.patchValue(this.card);
        }
        if (this.card && this.card.id) {
            this.form.get('number').setValidators(null);
            this.form.get('expirationDate').setValidators(null);
        }
        this.mainForm.addControl('card', this.form)
    }

    keyUpCCNumber(event): void {
        if (event.keyCode == 8 || event.keyCode == 46) {
            if (this.form.get('number').value == '') {
                this.form.get('number').setErrors(null);
            }
            return;
        }
        const control = this.form.get('number');
        if (control.valid) {
            this.focusElement('cc-exp-date');
        } else {
            if (this.form.get('number').value == '') {
                this.form.get('number').setErrors(null);
            }
        }
    }

    keyUPExpDate(event): void {
        const value = event.target.value;
        if (event.keyCode == 8 || event.keyCode == 46) {
            if (value === '' || value === null) {
                this.focusElement('cc-number');
            }
            return;
        }
        const control = this.form.get('expirationDate');
        if (control.valid) {
            this.focusElement('cc-cvc');
        }
    }

    keyUpCvv(event: any, ccNumber?: any) {
        const cvvValue = event.target.value;
        if (event.keyCode == 8 || event.keyCode == 46 && !this.card.id) {
            if (cvvValue === '' || cvvValue === null) {
                this.focusElement('cc-exp-date');
            }
            return;
        }
        if (!this.card.id) {
            ccNumber.resolvedScheme$.subscribe(res => {
                if (res && res != 'unknown' && cvvValue && cvvValue.length) {
                    this.validationCvv(res, cvvValue);
                }
            })
        } else {
            const brand = this.card.creditcarddata && this.card.creditcarddata.brand.toLocaleLowerCase();
            if (brand) {
                this.validationCvv(brand, cvvValue);
            } else {
                console.log('creditcarddata.brand is undefined')
            }
        }
    }

    validationCvv(res: string, cvvValue: any): void {
        const card = this.cards.filter(item => item.type === res)[0];
        const isValidCvv = card.cvvLength.filter(val => val === cvvValue.length).length;
        if (!isValidCvv) {
            this.form.get('cvv').setErrors({invalid: true})
        }
    }

    focusElement(elementId: string): void {
        const element = document.getElementById(elementId) as HTMLElement;
        element.focus();
        this.changeDetector.markForCheck();
    }

    close(): void {
        if(this.form.invalid){
            return this._formUtilsService.validation(this.form);
        }
        this.dialogRef.close()
    }
}
