import {Component, Inject, Input, OnInit} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-paid-status-block',
    templateUrl: './paid-status-block.component.html'
})
export class PaidStatusBlockComponent implements OnInit {

    @Input() paidStatusBlock;

    constructor(public _payService: PayService,
                public dialogRef: MatDialogRef<PaidStatusBlockComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        if (!this.paidStatusBlock) {
            this.paidStatusBlock = this.data;
        }
        this._payService.goStateEmitter.subscribe(res => {
            if (res && res.patch) {
                if (res.patch === 'isPaidStatusClose') {
                    this.dialogRef.close();
                }
            }
        });
    }

    get addonIsNotPay(): boolean {
        const items = this._payService.config.addons;
        if (items && items.length) {
            const isNotPays = items.filter(i => !i.isAlreadyPay);
            return isNotPays && isNotPays.length;
        } else {
            return false;
        }
    }

    close(): void {
        if (this.paidStatusBlock.callback) {
            this.paidStatusBlock.callback();
        }
        if (this.dialogRef) {
            this.dialogRef.close({tryAgain: true});
        }
    }

    callback() {
        if (this.paidStatusBlock.callback) {
            this.paidStatusBlock.callback();
        }
    }

    redirect() {
        this._payService.loading = true;
        this._payService.sendPostMessage({
            type: 'redirectToMain',
            data: {}
        });
    }

    reSelect() {
        this._payService.loading = true;
        const isPays = this._payService.config.addons.filter(i => i.isAlreadyPay);
        this._payService.sendPostMessage({
            type: 'reSelectAddon',
            data: {isAlreadyPayAddons: isPays}
        });
    }
}
