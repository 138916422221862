import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';

import numeral from 'numeral';

import {LocalService} from "./local.service";
import {MatomoTracker} from "ngx-matomo";
import {FormUtilsService} from "./form-utils.service";
import {LoggerService} from "../loggers/logger.service";
import Pusher from "pusher-js";
import {isObject} from "rxjs/internal-compatibility";

@Injectable()
export class PayService {

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    private _config: BehaviorSubject<any | null> = new BehaviorSubject({});
    private _user: BehaviorSubject<any | null> = new BehaviorSubject({});
    private _form: BehaviorSubject<any | null> = new BehaviorSubject({});
    private _paymentSettings: BehaviorSubject<any | null> = new BehaviorSubject({});
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _userCards: BehaviorSubject<any[]> = new BehaviorSubject([]);

    goStateEmitter: EventEmitter<any> = new EventEmitter<any>();

    titleBtn = 'PURCHASE';
    loadingBtn = false;

    pushInfo: any = {
        key: '7e30e7c146f87e5ee807',
        cluster: 'mt1',
        channel: 'events.',
        event: 'events.form',
        push_id: ''
    };

    constructor(private http: HttpClient,
                private snackBar: MatSnackBar,
                public matomoTracker: MatomoTracker,
                private _formUtilsService: FormUtilsService,
                private _loggerService: LoggerService,
                private localService: LocalService,) {
        this.config = new Config({});
        this.user = new User({});
        this._loggerService.user = this.user;

    }

    get loading(): any {
        return this._loading.getValue();
    }

    set loading(val) {
        this._loading.next(val);
    }

    get config(): any {
        return this._config.getValue();
    }

    set config(val) {
        this._config.next(val);
    }

    get userCards(): any {
        return this._userCards.getValue();
    }

    set userCards(val) {
        this._userCards.next(val);
    }

    get userCards$(): any {
        return this._userCards.asObservable();
    }

    get form(): any {
        return this._form.getValue();
    }

    set form(val) {
        this._form.next(val);
    }

    get paymentSettings(): any {
        return this._paymentSettings.getValue();
    }

    get user(): any {
        return this._user.getValue();
    }

    set user(val) {
        this._user.next(val);
        this._loggerService.user = this.user;
    }

    openSnackBar(message: string, isError: boolean = false) {
        this.snackBar.open(message, '', {
            panelClass: isError ? 'snack-bar-error' : '',
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    checkUrlConfig(callback): void {
        this.localService.checkUrlConfig(callback, this.config)
    }

    get hasAuthToken(): boolean {
        const token = this.localService.getItem(environment.authTokenKey + this.config.client_id);
        return token && token.length;
    }

    setToken(token): void {
        this.sendPostMessage({
            type: 'userLogged',
            data: {is_logged: true}
        });
        this.localService.setItem(environment.authTokenKey + this.localService.getItem(environment.clientId), token);
    }

    removeToken() {
        this.sendPostMessage({
            type: 'userLogged',
            data: {is_logged: false}
        });
        this.localService.removeItem(environment.authTokenKey + this.config.client_id)
    }

    getUrl(key: string) {
        return this.localService.getUrl(key);
    }

    checkEmail(data: any): Observable<any> {
        return this.http.post<any>(this.getUrl('API_EMAIL_CHECKING'), data);
    }

    loginRequest(data: any): Observable<any> {
        return this.http.post<any>(this.getUrl('API_LOGIN_URL'), data);
    }

    login(form, callback, cdr): void {
        if (form && form.invalid) {
            return this._formUtilsService.validation(form);
        }
        this.loading = true;
        const data = form.value;
        this.loginRequest(data).pipe(
            catchError(res => {
                this._loggerService.error('network', res);
                const message = this._formUtilsService.prepareServerError(res, form, cdr);
                this.openSnackBar(message, true);
                this.loading = false;
                return of(undefined);
            })
        ).subscribe(res => {
            if (res && res.access_token) {
                this.sendPostMessage({
                    type: 'addToken',
                    data: {token: res.access_token}
                });
                this.setToken(res.access_token);
                this.loadPaymentSettings();
                this.getUserCards();
                callback();
            }
            this.loading = false;
        });
    }

    getForm(id: string) {
        const url = this.getUrl('API_FORM_API').split('ID').join(id);
        return this.http.get<any>(url);
    }

    sendDiscount(formId, data) {
        const url = this.getUrl('API_DISCOUNT_URL').replace('ID', formId).replace('CODE', data);
        return this.http.get(url).pipe(map(res => res['data']));
    }

    pay(data) {
        let url = this.getUrl('API_PAY_API')
        if (url && url.length && url.includes('ORDER_ID')) {
            url = url.replace('ORDER_ID', this.config.orderId);
        }
        return this.http.post(url, data, {observe: 'response'});
        // return this.http.post(this.getUrl('API_PAY_API'), data);
    }

    payBalance(data) {
        const url = this.getUrl('API_PAY_TOP_UP_API').split('ID').join(data.form_id);
        return this.http.post(url, data, {observe: 'response'});
    }

    sendCompleteRequest(data) {
        return this.http.post(this.getUrl('API_PAY_API_COMPLETE'), data)
            .pipe(
                map(res => res['data']),
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    getUserByToken(): Observable<any> {
        return this.http.get<any>(this.getUrl('API_USERS_URL'))
            .pipe(
                map(res => {
                    this.user = res.data ? res.data : res;
                    if (this.user && this.user.email && this.user.email.length) {
                        this.user = {...this.user, ...{confirm_email: this.user.email}};
                        // this.user = {...this.user, ...{confirm_email: this.user.email, isCheckEmail: true}};
                    }
                    if (!this.user.first_name || !this.user.last_name) {
                        if (this.user.data && this.user.data.first_name) {
                            this.user.first_name = this.user.data.first_name;
                        }
                        if (this.user.data && this.user.data.last_name) {
                            this.user.last_name = this.user.data.last_name;
                        }
                    }
                    if (!this.user.first_name || !this.user.last_name) {
                        if (this.config.first_name) {
                            this.user.first_name = this.config.first_name;
                        }
                        if (this.config.last_name) {
                            this.user.last_name = this.config.last_name;
                        }
                    }
                    this.matomoTracker.setUserId(this.user.id);
                    return this.user;
                })
            );
    }

    getUserCards() {
        if (!this.hasAuthToken || this.userCards.length) {
            return;
        }
        return this.http.get<any>(this.getUrl('API_CREDITCARDS_API')).pipe(
            map(res => {
                this._userCards.next(res['data']['data']);
            })
        ).subscribe();
    }

    getUser(paidStatusBlock, callback = null) {
        paidStatusBlock.loading = true;
        if (callback) {
            callback({functionName: 'go', data: {title: '', key: 'isPaidStatus'}})
        }
        this.getUserByToken().subscribe(res => {
                if (res) {
                    if (callback) {
                        callback({functionName: 'pay', key: 'selectedMethod.type'})
                    }
                } else {
                    paidStatusBlock.loading = false;
                    this.removeToken();
                    if (callback) {
                        callback({functionName: 'checkAuthType', data: this.config.auth})
                    }
                }
            },
            error => {
                paidStatusBlock.loading = false;
                this._loggerService.error('network', error);
                this.removeToken();
                if (callback) {
                    callback({functionName: 'checkAuthType', data: this.config.auth})
                }
            });
    }

    loadPaymentSettings(): any {
        return this.http.get(this.getUrl('API_PUBLIC_CONFIG'))
            .pipe(tap((res: any) => {
                this._paymentSettings.next(res.data);
            })).subscribe();
    }

    setConfig(params) {
        this.config = new Config(params);
        this.user = new User(params);
        if (params.client_id) {
            this.localService.setItem(environment.clientId, params.client_id)
        }
        if (params.client_domain) {
            this.localService.setItem(environment.clientDomain, params.client_domain)
        }
        if (this.config.authToken) {
            this.setToken(params.authToken)
        } else {
            if (this.hasAuthToken) {
                this.removeToken();
            }
        }
    }

    setDonationParams(res, donation) {
        if (res.isDonation) {
            donation.active = (res.isDonation === 'true');
        }
        if (res.donation_options && res.donation_options.length) {
            donation.options = res.donation_options.split(',');
        }
    }

    sendPostMessage(message) {
        if (this.config.parentDomainToPostMessage) {
            parent.postMessage(JSON.stringify(message), this.config.parentDomainToPostMessage)
        }
    }

    resizedContainer(event): void {
        const data = {
            height: event.newHeight,
            type: 'resize'
        };
        this.sendPostMessage(data);
    }

    clickContainer(event: MouseEvent) {
        if (this.config.isShowCloseBtn && this.config.type === 'modal') {
            var container = document.getElementById("container");
            if (container) {
                if (event.target == container) {
                    this.closeForm()
                }
            }
        }
    }

    closeForm(): void {
        this.sendPostMessage({
            type: 'CloseForm',
            data: null
        })
    }

    checkMetadata(data: any): void {
        let d = {};
        if (this.config.metadata && this.config.metadata.length) {
            this.config.metadata.map(value => {
                d = {...d, ...value};
            })
        }
        if(isObject(d)){
            data.metadata = d;
        }
    }

    checkSuccessUrl(token): void {
        let url = this.config.success_url;
        if (url && url.includes('[auth_payment_token]')) {
            url = url.replace('[auth_payment_token]', token);
        }
        this.config.success_url = url;
    }

    addSuccessText(res): void {
        if (res.success_text) {
            this.sendPostMessage({
                type: 'addSuccessText',
                data: {success_text: res.success_text}
            });
        }
    }

    sendToken(res): void {
        if (res && res.headers) {
            const token = res.headers.get('x-authorization');
            if (token) {
                this.checkSuccessUrl(token);
                this.sendPostMessage({
                    type: 'addToken',
                    data: {token: token}
                });
            }
        }
    }

    showPaidStatus(paidStatusBlock, status: boolean | string, message: string) {
        paidStatusBlock.paidSuccess = status;
        paidStatusBlock.paidStatusText = message;
    }

    sendComplete(params = null, paidStatusBlock, cdr) {
        paidStatusBlock.loading = true;
        let data = {
            p: this.config.p,
            PayerID: this.config.PayerID,
            paymentId: this.config.paymentId,
            token: this.config.token
        };
        if (params) {
            data = params;
        }
        this.sendCompleteRequest(data).subscribe(res => {
            paidStatusBlock.loading = false;
            if (res) {
                this.checkPaidStatus(res, paidStatusBlock);
            } else {
                this.showPaidStatus(paidStatusBlock, false, 'Your transaction already marked like completed or cancelled. Feel free to contact support.');
            }
            cdr.markForCheck();
        }, error => {
            paidStatusBlock.loading = false;
            this.showPaidStatus(paidStatusBlock, false, 'Your transaction already marked like completed or cancelled. Feel free to contact support.');
            this._loggerService.error('network', error);
            cdr.markForCheck();
        });
    }

    subscribePusher(paidStatusBlock) {
        this.pushInfo.push_id = Math.random().toString(16).substr(2, 8) + Math.random().toString(16).substr(2, 8);
        const pusher = new Pusher(this.pushInfo.key, {
            cluster: this.pushInfo.cluster,
        });

        const channel = pusher.subscribe(this.pushInfo.channel + this.pushInfo.push_id);
        const that = this;
        channel.bind(this.pushInfo.event, function (this: any, data: any) {
            that.checkPaidStatus(data, paidStatusBlock);
        });
    }

    checkPaidStatus(data: any, paidStatusBlock) {
        this.loading = false;
        let isMessage = false;
        paidStatusBlock.loading = false;
        paidStatusBlock.showTryAgainButton = false;
        this.sendPostMessage({
            type: 'paymentStatus',
            data: {status: data.status}
        });
        switch (data.status) {
            case  'paid':
                this.showPaidStatusSuccess(data, isMessage, paidStatusBlock);
                break;
            case 'processing':
                paidStatusBlock.paidStatusText = data.message || 'Your payment Processing, you will get notification soon';
                this.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
                break;
            case 'failed':
                paidStatusBlock.paidStatusText = data.message || 'Your Payment Failed. Please check payment information that you type';
                this.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
                break;
            case 'forbidden':
                paidStatusBlock.paidStatusText = data.message || 'Sorry your payment declined. Try later';
                this.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
                break;
            default:
                console.log('check paid status --error switch');
        }
    }

    showPaidStatusSuccess(data: any, isMessage: boolean, paidStatusBlock) {
        this.sendPostMessage({
            type: 'successForm',
            data: {payment_id: data.id, id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this.matomoTracker.trackEcommerceOrder(data.id, this.form.total);
        this.matomoTracker.trackPageView();
        let url = this.config.success_url;
        if (url && url.length && url.includes('[paymentID]')) {
            url = url.replace('[paymentID]', data.payment_id);
        }
        if (this.config.sendPostMessageInsteadRedirect) {
            this.sendPostMessage({
                type: 'showRedirectInIframe',
                url: url
            })
            return;
        }
        if (url && url.length) {
            this.showPaidStatus(paidStatusBlock, 'redirect', 'Redirecting to the site...');
            paidStatusBlock.payRequestReturnUrl = url;
            this.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
            window.open(url, '_parent');
        } else {
            paidStatusBlock.paidSuccess = true;
            paidStatusBlock.paidStatusText = isMessage ? paidStatusBlock.paidStatusText : 'Your payment successful';
            this.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
        }
    }

}

function Config(params) {
    this.type = params.type || 'embed';
    this.form = params.form || null;
    this.client_domain = params.client_domain;
    this.client_id = params.client_id;
    this.parentDomainToPostMessage = params.parentDomain;
    this.parentSiteKey = params.parentSiteKey || null;
    this.total = params.total || 0;
    this.logo = params.logo === 'true' || false;
    this.titleBtn = params.titleBtn || 'PURCHASE';
    this.isComplete = params.isComplete === 'true' || false;
    this.isShowCloseBtn = params.isShowCloseBtn === 'true' || false;
    this.authToken = params.authToken && params.authToken.length && params.authToken !== 'undefined' && params.authToken !== 'null' ? params.authToken : undefined;
    this.hasAuthTokenParams = params.authToken && params.authToken.length && params.authToken !== 'undefined' && params.authToken !== 'null' || false;
    this.metadata = params.metadata ? JSON.parse(params.metadata) : null;
    this.orderId = params.order_id || '';
    this.cancelUrl = params.cancelUrl;
    this.insuranceTotal = params.insuranceTotal || null;
    this.insuranceText = params.insuranceText || null;
    this.parentUrlForRequest = params.parentUrl;
    this.returnUrl = params.returnUrl;
    this.cover = params.cover || null;
    this.success_url = params.success_url;
    this.success_url_email = params.success_url_email;
    this.paymentId = params.paymentId || null;
    this.p = params.p || null;
    this.PayerID = params.PayerID || null;
    this.token = params.token || null;
    this.showTotalBalanceForm = false;
    this.addAddonTotal = params.addAddonTotal || null;
    this.sendPostMessageInsteadRedirect = params.sendPostMessageInsteadRedirect === 'true' || false;
    this.addons = params.addons && params.addons.length ? params.addons.includes('formId') ? JSON.parse(params.addons)
        : params.addons.includes(',') ? params.addons.split(',') : [params.addons] : null;
    this.insurances = params.addons_insurances && params.addons_insurances.length ? params.addons_insurances.includes(',') ? params.addons_insurances.split(',') : [params.addons_insurances] : null;
    this.onlyAddon = params.onlyAddon === 'true' || false;
    this.customFormTitle = params.customFormTitle || null;
}

function User(params) {
    this.email = params.email || '';
    this.confirm_email = params.email || '';
    this.first_name = params.first_name || '';
    this.last_name = params.last_name || '';
    this.balance = params.balance;
    this.id = params.id;
    // this.isCheckEmail = params.email && params.email.length || false;
}
