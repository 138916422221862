import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LoggerService} from './logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private _loggerService: LoggerService,
    ) {
    }

    handleError(error: Error | HttpErrorResponse): void {
        this._loggerService.debug(error);
        console.error(error);
    }
}
