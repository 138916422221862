import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html'
})
export class TermsComponent {

    @Output() callbackEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _payService: PayService,
                public dialogRef: MatDialogRef<TermsComponent>,) {
    }

    saveTerms() {
        if(this.dialogRef.close){
            return this.dialogRef.close();
        }
        this.callbackEmitter.emit({accept_terms: true});
    }
}
