import {Component, OnInit, Input} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {FormUtilsService} from "../../shared/service/form-utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {COUNTRY} from "../../shared/consts/country.const";
import {map, startWith} from "rxjs/operators";
import {STATE_US} from "../../shared/consts/state.const";

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html'
})
export class BillingComponent implements OnInit {
    @Input() mainForm;
    @Input() smallInfo;
    form: FormGroup;

    countries: any[] = [];
    filteredCountries: Observable<any[]>;
    states: any[] = [];
    showStateUs = false;


    constructor(public _payService: PayService,
                public _formBuilder: FormBuilder,
                public _formUtilsService: FormUtilsService) {
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        if (this.form && this.form.valid) {
            return;
        }
        if (!this.countries || !this.countries.length) {
            this.prepareSelectorArray(COUNTRY, this.countries);
        }
        this.form = this._formBuilder.group({
            billing_country_obj: [this.countries[0], Validators.required],
            billing_country: ['', Validators.required],
            billing_zipcode: ['', Validators.required],
            billing_address: ['', Validators.required],
            billing_city: ['', Validators.required],
            billing_state: ['', Validators.required],
        }, {
            validator: this.getBillingCountryError()
        });
        if (this.mainForm.get('billing')) {
            this.selectCountry(this.form, this.form.get('billing_country_obj').value, 'billing_country', 'billing_state')
            this.form.patchValue(this.mainForm.get('billing').value)
        } else {
            this.selectCountry(this.form, this.countries[0], 'billing_country', 'billing_state')
        }
        this.mainForm.addControl('billing', this.form)

        this.filteredCountries = this.form.get('billing_country_obj').valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    return this._filter(value)
                })
            );
    }

    _filter(value: string | any): string[] {

        const filterValue = value && value.name ? value.name.toLowerCase() : value.toLowerCase();

        return this.countries.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    prepareSelectorArray(obj: any, array: any[]) {
        Object.keys(obj).map(key => {
            const item = {
                name: obj[key] + ' ' + key,
                code: key
            };
            array.push(item);
        });
    }

    selectCountry(form, value, fField, sField) {
        form.get(fField).setValue(value.code);
        if (value.code === 'US') {
            this.showStateUs = true;
            form.get(sField).setValue('');
            this.prepareSelectorArray(STATE_US, this.states);
        } else {
            form.get(sField).setValue('');
            this.showStateUs = false;
        }
    }

    getBillingCountryError(): any {
        return (formGroup: FormGroup) => {
            const value = formGroup.get('billing_country_obj').value;
            if (typeof value === 'string') {
                formGroup.get('billing_country_obj').setErrors({mustObj: true})
            }
        }
    }

    displayFn(item: any): string {
        return item && item.name ? item.name : item;
    }
}
