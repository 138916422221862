import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-select-card',
    templateUrl: './select-card.component.html'
})
export class SelectCardComponent implements OnInit {
    @Input() card;
    cards: any[] = [];

    @Output() callbackEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _payService: PayService,
                private _cdr: ChangeDetectorRef,
                public dialogRef: MatDialogRef<SelectCardComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if(!this.card){
            this.card = this.data;
        }
        this.getUserCards();
    }

    getUserCards() {
        if (this._payService.userCards && this._payService.userCards.length) {
            this.cards = this._payService.userCards;
            this._cdr.markForCheck();
            return;
        } else {
            this._payService.userCards$.subscribe(_ => {
                this.cards = this._payService.userCards;
                this._cdr.markForCheck();
            });
        }
    }

    select(_card): void {
        if(this.dialogRef.close){
            return this.dialogRef.close({_card});
        }
        this.callbackEmitter.emit(_card);
    }

}
