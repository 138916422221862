import {Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Inject} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {FormUtilsService} from "../../shared/service/form-utils.service";
import {FormGroup} from "@angular/forms";
import {LoggerService} from "../../shared/loggers/logger.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-discount',
    templateUrl: './discount.component.html'
})
export class DiscountComponent implements OnInit {
    @Input() discountCoupon;
    form: FormGroup;
    @Output() callbackEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _payService: PayService,
                public _cdr: ChangeDetectorRef,
                public _loggerService: LoggerService,
                public _formUtilsService: FormUtilsService,
                public dialogRef: MatDialogRef<DiscountComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if(!this.discountCoupon){
            this.discountCoupon = this.data;
        }
    }


    saveDiscountCoupon(): void {
        if (this.discountCoupon.invalid) {
            this.discountCoupon.markAsTouched();
            this._cdr.markForCheck();
            return;
        }
        this._payService.loading = true;
        this._payService.sendDiscount(this._payService.config.form, this.discountCoupon.value)
            .subscribe((res: any) => {
                if (res && res.discount && res.discount.success) {
                    if(this.dialogRef.close){
                        this._payService.loading = false;
                        return this.dialogRef.close({response: res, value: {discount: this.discountCoupon.value}});
                    }
                    this.callbackEmitter.emit(res)
                } else {
                    const message = res.discount && res.discount.message || "Discount not found.";
                    this.discountCoupon.setErrors({'serverError': message});
                    this._payService.openSnackBar(message, true);
                    this._loggerService.info(message);
                }
                this._payService.loading = false;
            })
    }


}
