import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../environments/environment';
import {PayService} from '../shared/service/pay.service';
import {LocalService} from "../shared/service/local.service";
import _ from 'lodash';
import {LoggerService} from '../shared/loggers/logger.service';
import {FormUtilsService} from "../shared/service/form-utils.service";
import {MatDialog} from "@angular/material/dialog";
import {PaidStatusBlockComponent} from "../components/paid-status-block/paid-status-block.component";
import {TermsComponent} from "../components/terms/terms.component";
import {SelectCardComponent} from "../components/select-card/select-card.component";
import {SignatureComponent} from "../components/signature/signature.component";
import {DiscountComponent} from "../components/discount/discount.component";
import {CardFormComponent} from "../components/card-form/card-form.component";
import {FrameModalComponent} from "../components/frame-modal/frame-modal.component";

@Component({
    selector: 'app-full-form',
    templateUrl: './full-form.component.html',
    styleUrls: ['./full-form.component.scss']
})
export class FullFormComponent implements OnInit {

    paidStatusBlock = {
        loading: false,
        payRequestReturnUrl: '',
        paidStatusText: '',
        paidSuccess: null,
        showTryAgainButton: false,
    }
    userBlock = {
        loading: false,
    }
    isLoadingAllForm = true;
    sendPayData: any = {};

    form: any = {};
    card: any = {};
    selectedMethod: any = {
        type: ''
    };

    iframeData = {
        loading: false,
        url: '',
        subscribeMessage: false
    }

    donation = {
        active: false,
        options: [],
        title: 'Amount'
    }

    mainForm: FormGroup;
    balanceForm: FormGroup;
    discountCoupon: FormControl = new FormControl(null, Validators.required);
    termsControl: FormControl = new FormControl(null, [Validators.required, Validators.requiredTrue]);

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                public _payService: PayService,
                private localService: LocalService,
                private _loggerService: LoggerService,
                public _formUtilsService: FormUtilsService,
                private _dialog: MatDialog,
                private changeDetector: ChangeDetectorRef) {
        this.localService.checkLocalStorage();
    }

    ngOnInit() {
        this.isLoadingAllForm = true;
        this.changeDetector.markForCheck();
        // для перевірки на місці відкриття форми (на сайті чи окремо у вкладці)
        if (environment.production) {
            if (window.self === window.top) {
                return;
            }
        }
        this.mainForm = this.fb.group({});
        this._payService.goStateEmitter.subscribe(res => {
            if (res && res.patch) {
                if (res.patch === 'isPaidStatus') {
                    this._dialog.open(PaidStatusBlockComponent, {
                        panelClass: 'custom-modal',
                        data: this.paidStatusBlock
                    }).afterClosed()
                        .subscribe(res => {
                            if (res && res.tryAgain) {
                                this.tryAgain();
                            }
                        })
                }
            }
        })
        this.getRouteParams();

    }

    getRouteParams(): void {
        this.route.queryParams.subscribe(res => {
            if (res && res.form) {
                this._payService.setConfig(res);
                this._payService.setDonationParams(res, this.donation)
                this._payService.checkUrlConfig(this.checkIsComplete.bind(this));
                this._payService.subscribePusher(this.paidStatusBlock);
            }
        });
    }

    checkIsComplete() {
        if (this._payService.config.isComplete) {
            if (this._payService.config.PayerID && this._payService.config.paymentId) {
                // this.paidStatusBlock.loading = true;
                this._payService.sendComplete(null, this.paidStatusBlock, this.changeDetector);
            } else {
                this._payService.showPaidStatus(this.paidStatusBlock, false, 'Payment was canceled');
            }
            return this._payService.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
        }
        if (this._payService.config.form) {
            this.getForm(this._payService.config.form);
        }
        this._payService.loadPaymentSettings();
        if (this._payService.config.hasAuthTokenParams) {
            this._payService.getUserByToken().subscribe();
        }
    }

    checkUser(): void {
        if (this._payService.config.hasAuthTokenParams && this._payService.config.auth != 'guest') {
            this._payService.getUser(this.userBlock);
        } else {
            this._payService.userCards = [];
            this._payService.removeToken();
        }
    }

    //Discount

    goDiscount(): void {
        this._dialog.open(DiscountComponent, {
            panelClass: 'custom-modal-without-padding',
            data: this.discountCoupon
        })
            .afterClosed().subscribe(res => {
            if (res && res.response) {
                this.sendPayData = {...this.sendPayData, ...res.value}
                this.form.total = res.response.discount.total;
                this.form.totalDiscount = res.response.discount.total_discount;
                this.form.oldTotal = res.response.total;
                this.form.oldMethods = [...this.form.methods];
                this.form.methods = res.response.methods;
            }
        });
    }

    deleteDiscountCoupon(): void {
        this.discountCoupon.setErrors(null);
        this.discountCoupon.setValue(null);
        this.discountCoupon.markAsUntouched();
        if (this.sendPayData.discount) {
            delete this.sendPayData.discount;
        }
        if (this.form.oldTotal || this.form.oldTotal === 0) {
            this.form.total = this.form.oldTotal;
            delete this.form.oldTotal;
        }
        if (this.form.oldMethods) {
            this.form.methods = this.form.oldMethods;
            delete this.form.oldMethods
        }
    }

    // Form

    getForm(formId: string) {
        this._payService.getForm(formId).subscribe(res => {
            if (res) {
                this.form = res.data;
                this._payService.form = this.form;
                if(_.has(this.form, 'data') && !_.has(this.form, 'data.cover')){
                    this.form.data.cover = this._payService.config.cover;
                }
                if (this.form && this.form.methods && this.form.methods.length) {
                    const indexMethodCard = this.form.methods.findIndex(m => m.type === 'creditcard');
                    if (indexMethodCard != -1) {
                        this.form.methods.splice(0, 0, this.form.methods.splice(indexMethodCard, 1)[0]);
                    }
                    this.selectMethod(this.form.methods[0]);
                }
                this.checkBalanceForm();
                this._payService.sendPostMessage({
                    type: 'initForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this._payService.matomoTracker.setEcommerceView(this.form.id, this.form.name, '', this.form.total);
                this._payService.matomoTracker.trackPageView();
                this.isLoadingAllForm = false;
            }
        });
    }

    checkBalanceForm(): void {
        if (this.form.data && this.form.data.balance) {
            this._payService.config.showTotalBalanceForm = !this._payService.config.total || this._payService.config.total <= 0;
            this.balanceForm = this.fb.group({
                total: [this._payService.config.total, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
                    Validators.min(1)]]
            });
        }
    }

    setDonationOptions(value) {
        if (this.balanceForm && this.balanceForm.get('total')) {
            this.balanceForm.get('total').setValue(value)
        }
    }

    selectMethod(method: any) {
        if (this.selectedMethod && method && method.id === this.selectedMethod.id) {
            return;
        }
        this.selectedMethod = method;
        if (method.type === 'creditcard') {
            this._payService.getUserCards();
        } else if (method.type === 'balance') {
            if (this._payService.user.balance < this.form.total) {
                return this._payService.openSnackBar('The amount of payment is more than you have on the balance', true);
            }
        }
    }

    openCardsList(): void {
        this._dialog.open(SelectCardComponent, {
            panelClass: 'custom-modal',
            data: this.card
        }).afterClosed()
            .subscribe(res => {
                if (res && res._card) {
                    this.card = res._card;
                    if (this.mainForm.get('billing')) {
                        this.mainForm.removeControl('billing')
                    }
                    if (this.mainForm.get('card')) {
                        this.mainForm.removeControl('card')
                    }
                    this.showCardModal();
                    this.changeDetector.markForCheck();
                }
            })
    }

    showCardModal(): void {
        this._dialog.open(CardFormComponent, {
            panelClass: 'custom-modal',
            data: {
                mainForm: this.mainForm,
                card: this.card,
                smallInfo: true
            }
        })
    }

    pay(methodType: string) {
        if (this.form.data && this.form.data.balance && this.balanceForm) {
            if (this.balanceForm.invalid) {
                return this._formUtilsService.validation(this.balanceForm);
            }
        }
        if (methodType === 'creditcard') {
            if (this.mainForm && this.mainForm.get('card')) {
                if (this.mainForm.get('card').invalid) {
                    if (this.card && this.card.id && this.mainForm.get('card').get('cvv').invalid) {
                        this.showCardModal();
                    }
                    return this._formUtilsService.validation(this.mainForm.get('card'));
                }
                if (!this.card || !this.card.id) {
                    const cardValue = this.mainForm.get('card').value;
                    cardValue.save = cardValue.save ? 1 : 0;
                    this.card = cardValue;
                }
            }
            if (this.mainForm && this.mainForm.get('billing')) {
                if (this.mainForm.get('billing').invalid) {
                    return this._formUtilsService.validation(this.mainForm.get('billing'));
                }
                this.card = {...this.card, ...this.mainForm.get('billing').value};
            }
        }

        if (!this.mainForm.get('user') && !this._payService.user.id) {
            return this.checkUser();
        } else {
            if (this.mainForm && this.mainForm.get('user')) {
                if (this.mainForm.get('user').invalid) {
                    return this._formUtilsService.validation(this.mainForm.get('user'));
                } else {
                    this._payService.user = this.mainForm.get('user').value;
                }
            }
        }
        if (this.termsControl.invalid) {
            return this.termsControl.markAsTouched();
        }
        this._payService.sendPostMessage({
            type: 'paymentMethod',
            data: {method: methodType, id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this._payService.matomoTracker.trackEvent('Form', 'Form payment method', methodType);
        this._payService.matomoTracker.addEcommerceItem(this.form.id, this.form.name, '', parseFloat(this.form.total), 1);
        this._payService.matomoTracker.trackPageView();
        this._payService.loading = true;

        let domain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        let returnUrl = methodType === 'creditcard' ? `${environment.closeHtmlPatch}?domain=${domain}` : this._payService.config.returnUrl

        let data = {
            form_id: this._payService.config.form,
            method_id: this.selectedMethod.id,
            push_id: this._payService.pushInfo.push_id,
            user: this._payService.user.id ? this._payService.user : this._formUtilsService.checkEmptyField(this.mainForm, 'user'),
            returnUrl: returnUrl,
            cancelUrl: this._payService.config.cancelUrl,
            accept_terms: this.termsControl.value
        };
        if (this._payService.config.parent_id) {
            data['parent_id'] = this._payService.config.parent_id;
        }
        if (this.form.data.balance) {
            data = {...data, ...this.balanceForm.value};
        }

        if (methodType === 'creditcard') {
            if (this.card && this.card.id) {
                data['creditcard'] = {
                    id: this.card.id,
                    cvv: this.mainForm.get('card').get('cvv').value
                };
            } else {
                this.prepareCreditcardDate();
                data['creditcard'] = this.card;
            }
        }

        this._payService.checkMetadata(data);

        if (this.sendPayData.discount) {
            data['discount'] = this.sendPayData.discount;
        }
        this.sendPayData = {...this.sendPayData, ...data};
        this.payRequest(this.sendPayData, methodType);
    }

    prepareCreditcardDate() {
        if (this.mainForm.get('card')) {
            const date: string = this.mainForm.get('card').get('expirationDate').value;
            const split = date.split(' / ');
            this.card.month = split[0];
            this.card.year = split[1];
        }

        this.card.first_name = this._payService.user.first_name;
        this.card.last_name = this._payService.user.last_name;

        if (this.card.expirationDate) {
            delete this.card.expirationDate;
        }
    }

    payRequest(data: any, methodType: string, skip = false) {
        if (this._payService.paymentSettings.signature_active && (!data.signature || !data.signature.length)) {
            return this.initSignature();
        }
        this.paidStatusBlock.loading = true;
        this._payService.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
        var request = this._payService.pay(data)
        if (this.form && this.form.data.balance) {
            request = this._payService.payBalance(data)
        }
        request.pipe(
            catchError(res => {
                this._payService.sendToken(res);
                if (res && res.error && res.error.error && (res.error.error.accept_terms || res.error.error.signature)) {
                    this.paidStatusBlock.loading = false
                    this.checkPayRequestError(res.error);
                    return of(undefined);
                }
                this._loggerService.error('network', {...res});
                this.paidStatusBlock.loading = false;
                this.checkPayRequestError(res.error);
                return of(undefined);
            }),
        ).subscribe(res => {
            if (res) {
                const data = res.body.data;
                this._payService.sendPostMessage({
                    type: 'submitForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this._payService.sendToken(res);
                this._payService.addSuccessText(data);
                this._payService.matomoTracker.trackEvent('Form', 'Submit form', this.form.name);
                this._payService.matomoTracker.trackEcommerceCartUpdate(parseFloat(this.form.total));
                this._payService.matomoTracker.trackPageView();
                if (data && data.return_url && data.return_url.length && methodType === 'creditcard') {
                    this.subscribeIframe3DsMessage();
                    this.iframeData.url = data.return_url;
                    this._dialog.open(FrameModalComponent, {
                        panelClass: 'custom-modal-without-padding',
                        data: this.iframeData
                    });
                    return;
                }
                if (data && data.success && (methodType === 'cryptocurrency' || methodType === 'paypal' || methodType === 'redirect')) {
                    this.paidStatusBlock.loading = false;
                    this.paidStatusBlock.paidSuccess = 'redirect';
                    this.paidStatusBlock.payRequestReturnUrl = data.return_url;
                    this.paidStatusBlock.paidStatusText = 'Redirecting to the site...';
                    window.open(data.return_url, '_parent');
                    this._payService.loading = false;
                } else {
                    this._payService.checkPaidStatus(data, this.paidStatusBlock);
                }
            }
        });
    }

    subscribeIframe3DsMessage() {
        if (this.iframeData.subscribeMessage) {
            return;
        }
        window.addEventListener('message', (ev) => {
            if (ev && ev.data && ev.data.type && ev.data.type == "webpackClose") {
                return;
            }
            console.log(ev);
            if (ev && ev.data && typeof ev.data === 'string') {
                const data = JSON.parse(ev.data)
                if (data && data.close_confirm_iframe) {
                    this._payService.goStateEmitter.emit({title: '', patch: 'isPaidStatus'})
                    this._payService.sendComplete(data, this.paidStatusBlock, this.changeDetector);
                }
            }
        }, false);
        this.iframeData.subscribeMessage = true;
    }

    back(e): void {
        e.stopPropagation();
        this.card = {};
        this.changeDetector.markForCheck();
    }

    checkPayRequestError(res: any) {
        if (res && res.error && res.error.accept_terms) {
            this._payService.matomoTracker.trackEvent('Form', 'Form action', 'Terms part');
            this._payService.sendPostMessage({
                type: 'FormError',
                data: {error: 'terms', id: this.form.id, name: this.form.name, total: this.form.total}
            });
            this._payService.loading = false;
            this._payService.goStateEmitter.emit({title: '', patch: 'isPaidStatusClose'})
            this.initAcceptTerms();
        } else if (res && res.error && res.error.signature) {
            this._payService.loading = false;
            this._payService.goStateEmitter.emit({title: '', patch: 'isPaidStatusClose'})
            this.initSignature(res.error.signature[0]);
        } else if (res && res.error && res.error.method_id) {
            this.showDefaultError(res.error.method_id[0]);
        } else if (res && res.error && res.error.form_id) {
            this.showDefaultError(res.error.form_id[0]);
        } else {
            if (res && res.error && res.error.message) {
                this.showDefaultError(res.error.message);
            } else {
                this.showDefaultError('Your Payment Was Declined. Please Try Later or Change Payment Method');
            }
        }
    }

    showDefaultError(message): void {
        this._payService.loading = false;
        this.paidStatusBlock.showTryAgainButton = true;
        this.paidStatusBlock.loading = false;
        this._payService.showPaidStatus(this.paidStatusBlock, false, message)
        this._payService.openSnackBar(message, true);
    }

    tryAgain() {
        if (this._payService.config.isComplete) {
            this._payService.config.isComplete = false;
            this.checkIsComplete();
        } else {
            if (this.card && this.card.id) {
                this.card = {};
                if (this.mainForm.get('card')) {
                    if (this.mainForm.get('card').get('cvv')) {
                        this.mainForm.get('card').get('cvv').setValue('')
                    }
                }
            }
        }
    }


    // Signature

    // set default value in signature pad
    initSignature(error = '') {
        const message = error && error.length ? error : 'The signature is required.';
        this._payService.openSnackBar(message, true);
        this._payService.sendPostMessage({
            type: 'FormError',
            data: {error: 'signature', id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this._dialog.open(SignatureComponent,
            {panelClass: 'custom-modal-without-padding',})
            .afterClosed().subscribe(res => {
            if (res.signature) {
                this.sendPayData = {...this.sendPayData, ...res};
                this.payRequest(this.sendPayData, this.selectedMethod.type);
            }
        })
    }

    initAcceptTerms() {
        this._dialog.open(TermsComponent, {
            panelClass: 'custom-modal-without-padding',
        });
    }

}


