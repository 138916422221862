import {Component, OnInit, Input, Inject} from '@angular/core';
import {PayService} from "../../shared/service/pay.service";
import {FormUtilsService} from "../../shared/service/form-utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-frame-modal',
    templateUrl: './frame-modal.component.html'
})
export class FrameModalComponent implements OnInit {

    @Input() iframeData;

    constructor(public _payService: PayService,
                public _formUtilsService: FormUtilsService,
                public dialogRef: MatDialogRef<FrameModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if(!this.iframeData){
            this.iframeData = this.data;
        }
    }
}
