import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import picks from "../../../assets/json/picks.json";
import experience from "../../../assets/json/experience.json";
import dlucky from "../../../assets/json/dlucky.json";
import posting from "../../../assets/json/posting.json";
import posttera from "../../../assets/json/posttera.json";

const localUrlConfig = {
    API_LOGIN_URL: environment.apiUrl + 'auth',
    API_USERS_URL: environment.apiUrl + 'me',
    API_FORM_API: environment.apiUrl + 'forms/',
    API_CREDITCARDS_API: environment.apiUrl + 'creditcards_preview',
    API_PAY_API: environment.apiUrl + 'pay',
    API_PAY_API_COMPLETE: environment.apiUrl + 'complete',
    API_PUBLIC_CONFIG: environment.apiUrl + 'site_public_config',
};


@Injectable()
export class LocalService {

    localData: any;
    isLocalStorage: boolean;
    urlConfig: any = undefined;

    jsons = {
        picks: picks,
        experience: experience,
        dlucky: dlucky,
        posting: posting,
        posttera: posttera
    }

    constructor(private http: HttpClient) {
    }

    getUrl(key: string) {
        let url = '';
        if (this.urlConfig) {
            Object.keys(this.urlConfig).map(res => {
                if (res === key) {
                    url = this.urlConfig[res];
                }
            })
        }
        if (!url || !url.length) {
            url = localUrlConfig[key];
        }
        return url;
    }

    getUrlConfig(urlConfig: string) {
        if (urlConfig && urlConfig.length) {
            return this.http.get<any>(urlConfig).pipe(map(res => {
                this.urlConfig = res;
                return res;
            }));
        } else {
            console.log('api url config - не найдено')
        }
    }

    checkUrlConfig(callback, config): any {
        if (config.parentSiteKey) {
            if (this.hasLocalUrlConfig(config.parentSiteKey)) {
                this.setLocalUrlConfig(config.parentSiteKey);
                callback()
                return;
            }
        }
        if (config.parentUrlForRequest) {
            this.getUrlConfig(config.parentUrlForRequest).subscribe(res => {
                    if (res) {
                        callback();
                    }
                },
                error => {
                    callback();
                })
        } else {
            callback();
        }
    }

    hasLocalUrlConfig(key: string): boolean {
        return !!this.jsons[key]
    }

    setLocalUrlConfig(key: string): void {
        this.urlConfig = this.jsons[key];
    }


    checkLocalStorage() {
        try{
            this.isLocalStorage = !!localStorage;
        } catch (e){
            this.localData = {};
            this.isLocalStorage = false;
        }
        
    }

    setItem(path: string, data: any): void {
        this.isLocalStorage ? localStorage.setItem(path, data) : this.localData[path] = data;
    }

    getItem(path: string): any {
        return this.isLocalStorage ? localStorage.getItem(path) : this.localData[path];
    }

    removeItem(path): void {
        if (this.isLocalStorage) {
            if (localStorage.getItem(path)) {
                localStorage.removeItem(path)
            }
        } else {
            if (this.localData[path]) {
                delete this.localData[path];
            }
        }
    }

}
