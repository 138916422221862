/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';
import {isObject} from "rxjs/internal-compatibility";
import {FormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class FormUtilsService {

    constructor() {
    }

    validation(form: any, collapse = false, collapseName = '', arrCollapse?: any[]): void {
        const controls = form.controls;
        Object.keys(controls).forEach(controlName => {
            if (collapse && controls[controlName].invalid && arrCollapse) {
                arrCollapse.push(controlName);
            }
            if (controls[controlName].controls) {
                this.validation(controls[controlName], controlName === collapseName, controlName, arrCollapse);
            }
            controls[controlName].markAsTouched();
        });
    }

    checkEmptyField(form: any, key, data = null): any {
        if (!data && form && form.get(key) && form.get(key).value) {
            data = form.get(key).value;
        }
            Object.keys(data).forEach(fieldName => {
                if (isObject(data[fieldName])) {
                    this.checkEmptyField(form, '', data[fieldName]);
                } else if (!data[fieldName] && data[fieldName] !== 0 && data[fieldName] !== false) {
                    delete data[fieldName];
                } else if (typeof data[fieldName] === 'boolean') {
                    data[fieldName] = data[fieldName] ? 1 : 0;
                }
            });
            return data;
    }

    changeTypePassword(passwordField: any): void {
        passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password';
    }

    getErrorMessage(form: FormGroup, controlName: string, minLength: number = 3, maxlength: number = 320): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('email')) {
            return 'Email is not valid';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        if (control.hasError('mustMatch')) {
            return 'Email and Confirm Email do not match';
        }
        if (control.hasError('wrongEnding')) {
            return 'Please edit your email. It contains the wrong ending';
        }
        return control.hasError('required') ? 'Field is required' : '';
    }


    getIconCardStyle(ccNumber: any, icon): any {
        var subject = new BehaviorSubject(null);
        ccNumber.resolvedScheme$.subscribe(res => {
            if (res && res.length && res != 'unknown') {
                const isThisCard = res === icon.name;
                const display = isThisCard ? 'block' : 'none';
                subject.next(display);
            } else {
                subject.next('block');
            }
        })
        return subject.getValue();
    }

    mustMatch(controlName: string, matchingControlName: string): any {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
                return;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({mustMatch: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    isControlHasError(form: FormGroup, controlName: string, validationType: string): boolean {
        const control = form.controls[controlName];
        if (!control) {
            return false;
        }

        return control.hasError(validationType) && (control.touched);
    }

    prepareServerError(response: any, form: FormGroup, cdr: ChangeDetectorRef): any {
        if (!response || !response.error) {
            return console.log('!response.error');
        }
        let errors = response.error.errors;
        if (!errors && response.error.error && typeof response.error.error !== 'string') {
            errors = response.error.error;
        }
        if (errors) {
            Object.keys(errors).map(field => {
                let error = '';
                if (errors[field] && errors[field].length) {
                    errors[field].map(text => {
                        error += text;
                    });
                }
                form.controls[field].setErrors({'serverError': error});
            });
            // Mark for check
            cdr.markForCheck();
            return response.error.message ? response.error.message : 'The given data was invalid.';
        } else {
            // Show the error message
            let message = 'The given data was invalid.';
            if (response.error.error && typeof response.error.error === 'string') {
                message = response.error.error;
            } else if (response.error && response.error === 'string') {
                message = response.error;
            }
            return message;
        }
    }
}
