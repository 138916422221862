import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {NgModule, ErrorHandler, SkipSelf, Optional} from '@angular/core';

import {AppComponent} from './app.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDialogModule,
    MatIconModule,
    MatInputModule, MatListModule, MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule, MatSnackBarModule, MatTabsModule
} from '@angular/material';
import {FormComponent} from './form/form.component';
import {PayService} from './shared/service/pay.service';
import {InterceptService} from './shared/service/intercept.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {CreditCardDirectivesModule} from 'angular-cc-library';
import {LocalService} from './shared/service/local.service';
import {CheckScrollDirective} from './shared/directive/check-scroll.directive';
import {MatomoModule} from 'ngx-matomo';
import {AngularResizedEventModule} from "angular-resize-event";
import {MatIconRegistry} from "@angular/material/icon";
import {LoggerService} from "./shared/loggers/logger.service";
import {GlobalErrorHandler} from "./shared/loggers/global-error.handler";
import {QRCodeModule} from "angularx-qrcode";
import {FormUtilsService} from "./shared/service/form-utils.service";
import {ComponentsModule} from "./components/components.module";
import {FullFormComponent} from "./full-form/full-form.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
    declarations: [
        AppComponent,
        FormComponent,
        FullFormComponent,
        CheckScrollDirective,
    ],
    entryComponents: [
        FormComponent,
        FullFormComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        MatomoModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([
            {
                path: 'pay-form',
                component: AppComponent,
                children: [
                    {
                        path: '',
                        component: FormComponent,
                    },
                    {
                        path: 'full',
                        component: FullFormComponent,
                    },
                ]
            },
            {path: '**', redirectTo: 'pay-form', pathMatch: 'full'},
        ]),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatIconModule,
        MatCardModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDialogModule,
        MatListModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        CreditCardDirectivesModule,
        AngularResizedEventModule,
        QRCodeModule,
        ComponentsModule,
        MatSlideToggleModule,
    ],
    providers: [
        InterceptService,
        LoggerService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        PayService,
        FormUtilsService,
        LocalService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {


    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: AppModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-outline.svg'));
    }
}
